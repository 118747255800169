import React, { useState, useEffect } from 'react';
import { AutocompleteInput, useGetList } from 'react-admin';

export const ProjectCategoryInput = ({ value, onChange }) => {
  const { data, isLoading } = useGetList('project_categories', { pagination: null });
  const [categories, setCategories] = useState({});
  useEffect(() => {
    if (!data) return;
    const categories = {};
    data.forEach(category => {
      categories[category.id] = category;
    });
    setCategories(categories);
  }, [data, isLoading]);

  return (
    <AutocompleteInput
      fullWidth
      source="project_category_id"
      choices={data}
      optionText="name"
      optionValue="id"
      groupBy={record => categories[record.parent_category_id]?.name || 'Без категории'}
      isLoading={isLoading}
    />
  );
};
