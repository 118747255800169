export const ColorMarkSpec = {
  group: 'inline',
  inclusive: false,
  attrs: {
    color: { default: '#000000' },
  },
  toDOM(node) {
    return [
      'span',
      {
        'data-color': node.attrs.color,
        style: `color: ${node.attrs.color}`,
      },
      0,
    ];
  },
  parseDOM: [
    {
      tag: 'span[data-color]',
      getAttrs: dom => {
        return {
          color: dom.dataset.color || '',
        };
      },
    },
  ],
};
