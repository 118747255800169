import React from 'react';
import { Datagrid, List, TextField, Show, ShowButton, SimpleShowLayout } from 'react-admin';
import ActionButtons from './components/expertStatusRequestsActionButtons';

export const ExpertStatusRequestList = props => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid>
      <TextField source="user.id" label="User ID" />
      <TextField source="user.email" label="Email" />
      <TextField source="user.first_name" label="First Name" />
      <TextField source="user.last_name" label="Last Name" />
      <TextField source="organization" />
      <TextField source="position" />
      <TextField source="status" />
      <ShowButton />
      <ActionButtons />
    </Datagrid>
  </List>
);

export const ExpertStatusRequestShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="user.email" label="Email" />
      <TextField source="user.first_name" label="First Name" />
      <TextField source="user.last_name" label="Last Name" />
      <TextField source="organization" />
      <TextField source="position" />
      <TextField source="motivation" />
      <TextField source="experience" />
      <TextField source="ready_for_discussions" />
      <TextField source="status" />
      <ActionButtons />
    </SimpleShowLayout>
  </Show>
);
