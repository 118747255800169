import React from 'react';
import { useMediaQuery } from '@mui/material';
import {
  Menu as RaMenu,
  MenuItemLink,
  useResourceDefinitions,
  useSidebarState,
  useTranslate,
  useGetResourceLabel,
} from 'react-admin';
import DefaultIcon from '@mui/icons-material/ViewList';

export const Menu = props => {
  const { onMenuClick, logout } = props;
  const [open] = useSidebarState();
  const resources = useResourceDefinitions();
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const t = useTranslate();
  const getResourceLabel = useGetResourceLabel();

  return (
    <RaMenu {...props}>
      {Object.entries(resources).map(([name, resource]) => (
        <MenuItemLink
          key={name}
          to={`/${name}`}
          primaryText={getResourceLabel(name, 2)}
          leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      ))}
      <MenuItemLink
        key="articles_page_layout"
        to="/articles_page_layout"
        primaryText={getResourceLabel('articles_page_layout', 1)}
        leftIcon={<DefaultIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      {isXSmall && logout}
    </RaMenu>
  );
};

export default Menu;
