import param from 'jquery-param';
import pluralize from 'pluralize';
import { fetchUtils, HttpError } from 'react-admin';

const stagingToken = localStorage.getItem('stagingToken');
const UNPROCESSABLE_ENTITY_STATUS_CODE = 422;

// fork of
// https://github.com/marmelab/react-admin/tree/master/packages/ra-data-simple-rest
const simpleRailsRestProvider = (
  apiUrl,
  httpClient = fetchUtils,
  countHeader = 'X-Total-Count'
) => ({
  getList: (resource, params = {}) => {
    const query = {
      filter: params.filter,
    };

    if (params.pagination) {
      const { page, perPage } = params.pagination;
      query.pagination = { page, per_page: perPage };
    }

    if (params.sort) {
      const { field, order } = params.sort;
      query.sort = [field, order];
    }

    const basePath = params.adminRoute ? `${apiUrl}/admin` : apiUrl;

    const url = `${basePath}/${resource}?${param(query)}`;
    const options = {};

    return httpClient(url, options).then(({ headers, json }) => {
      if (!headers.has(countHeader)) {
        throw new Error(
          `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
        );
      }
      return {
        data: json,
        total:
          countHeader === 'Content-Range'
            ? parseInt(headers.get('content-range').split('/').pop(), 10)
            : parseInt(headers.get(countHeader.toLowerCase())),
      };
    });
  },

  getOne: (resource, params) => {
    const basePath = params.adminRoute ? `${apiUrl}/admin` : apiUrl;

    return httpClient(`${basePath}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    }));
  },

  getMany: (resource, params) => {
    const query = {
      filter: { id: params.ids },
    };

    const basePath = params.adminRoute ? `${apiUrl}/admin` : apiUrl;

    const url = `${basePath}/${resource}?${param(query)}`;
    return httpClient(url).then(({ json }) => {
      return { data: json };
    });
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const query = {
      sort: [field, order],
      pagination: {
        page,
        per_page: perPage,
      },
      filter: {
        ...params.filter,
        [params.target]: params.id,
      },
    };

    const basePath = params.adminRoute ? `${apiUrl}/admin` : apiUrl;

    const url = `${basePath}/${resource}?${param(query)}`;
    const options = {};

    return httpClient(url, options).then(({ headers, json }) => {
      if (!headers.has(countHeader)) {
        throw new Error(
          `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
        );
      }
      return {
        data: json,
        total:
          countHeader === 'Content-Range'
            ? parseInt(headers.get('content-range').split('/').pop(), 10)
            : parseInt(headers.get(countHeader.toLowerCase())),
      };
    });
  },

  update: (resource, params) => {
    const basePath = params.adminRoute ? `${apiUrl}/admin` : apiUrl;

    return httpClient(`${basePath}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify({
        [pluralize.singular(resource)]: params.data,
      }),
    }).then(({ json }) => ({ data: json }));
  },

  // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
  updateMany: (resource, params) => {
    const basePath = params.adminRoute ? `${apiUrl}/admin` : apiUrl;

    return Promise.all(
      params.ids.map(id =>
        httpClient(`${basePath}/${resource}/${id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        })
      )
    ).then(responses => ({ data: responses.map(({ json }) => json.id) }));
  },

  create: (resource, params) => {
    const basePath = params.adminRoute ? `${apiUrl}/admin` : apiUrl;

    return httpClient(`${basePath}/${resource}`, {
      method: 'POST',
      body: JSON.stringify({
        [pluralize.singular(resource)]: params.data,
      }),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }));
  },

  delete: (resource, params) => {
    const basePath = params.adminRoute ? `${apiUrl}/admin` : apiUrl;

    return httpClient(`${basePath}/${resource}/${params.id}`, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'text/plain',
        'X-Staging-Token': stagingToken,
      }),
    }).then(({ json }) => ({ data: json }));
  },

  // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource, params) => {
    const basePath = params.adminRoute ? `${apiUrl}/admin` : apiUrl;

    return Promise.all(
      params.ids.map(id =>
        httpClient(`${basePath}/${resource}/${id}`, {
          method: 'DELETE',
          headers: new Headers({
            'Content-Type': 'text/plain',
            'X-Staging-Token': stagingToken,
          }),
        })
      )
    ).then(responses => ({
      data: responses.map(({ json }) => json.id),
    }));
  },
});

export const apiUrl = process.env.REACT_APP_API_URL;
export const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({
      Accept: 'application/json',
      'X-Staging-Token': stagingToken,
    });
  }

  const jwt = localStorage.getItem('jwt');
  options.headers.set('Authorization', `Bearer ${jwt}`);
  return fetchUtils.fetchJson(url, options);
};

export const handleRailsError = error => {
  if (error instanceof HttpError) {
    const { status, body } = error;
    if (status === UNPROCESSABLE_ENTITY_STATUS_CODE) {
      const message = Object.entries(body)
        .map(([key, value]) => `${key} ${value}`)
        .join('; ');

      return Promise.reject(new Error(message));
    }
  }

  return Promise.reject(error);
};

const dataProvider = simpleRailsRestProvider(apiUrl, httpClient, 'X-Total-Count');
// const dataProvider = simpleRestProvider(apiUrl, httpClient, 'X-Total-Count' );

export default dataProvider;
