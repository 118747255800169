import React, { useCallback, useEffect, useRef, useState } from 'react';
import { HexColorPicker } from 'react-colorful';

import useClickOutside from '../../utils/useClickOutside';
import { RTESelectionChangeEventName } from './common';

const ColorPicker = ({ view, colorMarkType, onChange, defaultColor = '#000', iconName }) => {
  const popover = useRef();
  const [isOpen, toggle] = useState(false);
  const [color, setColor] = useState(defaultColor);

  const updateSwatchColor = useCallback(() => {
    const { doc, selection } = view.state;
    let currentColor = defaultColor;
    let { from, to } = selection;
    if (from === to) {
      to += 1;
    }
    doc.nodesBetween(from, to, node => {
      if (node.isText) {
        currentColor =
          node.marks.find(mark => mark.type.name === colorMarkType.name)?.attrs[
            colorMarkType.name
          ] ?? defaultColor;
      }
    });
    setColor(currentColor);
  }, [colorMarkType.name, defaultColor, view.state]);

  useEffect(() => {
    document.addEventListener(RTESelectionChangeEventName, event => {
      updateSwatchColor();
    });
  }, [updateSwatchColor]);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  const openPicker = useCallback(() => {
    toggle(true);
  }, []);

  const handleChange = useCallback(
    color => {
      setColor(color);
      onChange?.(color);
    },
    [onChange]
  );

  return (
    <div className="picker">
      <div className="swatch" style={{ borderBottomColor: color }} onClick={openPicker}>
        <i className={`fa-solid fa-${iconName}`}></i>
      </div>

      {isOpen && (
        <div className="popover" ref={popover}>
          <HexColorPicker color={color} onChange={handleChange} />
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
