import { schema } from 'prosemirror-schema-basic';

// based on https://github.com/ProseMirror/prosemirror-markdown/blob/6107527995873d6199bc533a753b614378747056/src/schema.ts
export const RedBulletListNodeSpec = {
  name: 'red_bullet_list',
  content: 'list_item+',
  group: 'block',
  parseDOM: [
    {
      tag: 'ul.red-bullet',
    },
  ],
  toDOM(node) {
    return ['ul', { class: 'red-bullet' }, 0];
  },
};
