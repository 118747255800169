import { registerResourceHandler } from './proxy';
import baseDataProvider, { apiUrl, httpClient } from './base';
import { dataURLToBlob } from '../../utils/filesHelper';

const handlePreviewImg = (articleID, oldData, newData) => {
  const previewImgUrl = newData.get('article[preview_img_url]');
  newData.delete('article[preview_img_url]');

  if (!!oldData.preview_img_url && !previewImgUrl) {
    const removeImageUrl = new URL(`${apiUrl}/admin/articles/${articleID}/preview_image`);
    httpClient(removeImageUrl, {
      method: 'DELETE',
    });
  } else if (!!previewImgUrl && oldData.preview_img_url !== previewImgUrl) {
    const previewImgBlob = dataURLToBlob(previewImgUrl);
    newData.append('article[preview_img]', previewImgBlob, 'preview_img');
  }
};

const handleHeaderImg = (articleID, oldData, newData) => {
  const headerImgUrl = newData.get('article[header_img_url]');
  newData.delete('article[header_img_url]');

  if (!!oldData.header_img_url && !headerImgUrl) {
    const removeImageUrl = new URL(`${apiUrl}/admin/articles/${articleID}/header_image`);
    httpClient(removeImageUrl, {
      method: 'DELETE',
    });
  } else if (!!headerImgUrl && oldData.header_img_url !== headerImgUrl) {
    const headerImgBlob = dataURLToBlob(headerImgUrl);
    newData.append('article[header_img]', headerImgBlob, 'header_img');
  }
};

const articles = {
  getList: async (_resource, params) => {
    params = {
      ...params,
      adminRoute: true,
    };
    const result = await baseDataProvider.getList('articles', params);

    return result;
  },
  getMany: async (_resource, params) => {
    params = {
      ...params,
      adminRoute: true,
    };
    const result = await baseDataProvider.getMany('articles', params);

    return result;
  },
  getOne: async (_resource, params) => {
    params = {
      ...params,
      adminRoute: true,
    };
    const result = await baseDataProvider.getOne('articles', params);

    return result;
  },
  // we use form data here
  create: (_resource, params) => {
    if (!!params.data.get('article[preview_img_url]')) {
      params.data.set(
        'article[preview_img]',
        dataURLToBlob(params.data.get('article[preview_img_url]')),
        'preview_img'
      );
      params.data.delete('article[preview_img_url]');
    }

    if (!!params.data.get('article[header_img_url]')) {
      params.data.set(
        'article[header_img]',
        dataURLToBlob(params.data.get('article[header_img_url]')),
        'header_img'
      );
      params.data.delete('article[header_img_url]');
    }

    return httpClient(`${apiUrl}/admin/articles`, {
      method: 'POST',
      body: params.data,
    }).then(({ json }) => ({ data: json }));
  },
  // we use form data here
  update: (_resource, params) => {
    handlePreviewImg(params.id, params.previousData, params.data);
    handleHeaderImg(params.id, params.previousData, params.data);

    return httpClient(`${apiUrl}/admin/articles/${params.id}`, {
      method: 'PUT',
      body: params.data,
    }).then(({ json }) => ({ data: json }));
  },
};

registerResourceHandler('articles', 'getList', articles.getList);
registerResourceHandler('articles', 'getMany', articles.getMany);
registerResourceHandler('articles', 'getOne', articles.getOne);
registerResourceHandler('articles', 'create', articles.create);
registerResourceHandler('articles', 'update', articles.update);
