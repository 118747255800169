import compact from 'lodash/compact';
import difference from 'lodash/difference';

const buildNestedAttributesArray = (original = [], modified = []) => {
  const originalIds = original.map(el => el.id);
  const modifiedIds = compact(modified.map(el => el.id));
  const arrToDelete = difference(originalIds, modifiedIds).map(id => ({
    id: id,
    _destroy: 1,
  }));

  return [...modified, ...arrToDelete];
};

export { buildNestedAttributesArray };
