import { TextAlign, TextAlignValues } from '../common';

export const ParagraphNodeSpec = {
  content: 'inline*',
  group: 'block',
  attrs: {
    align: { default: TextAlign.Left },
  },
  parseDOM: [
    {
      tag: 'p',
      getAttrs: dom => {
        const align = TextAlignValues.find(value => dom.classList.contains(value));
        return align ? { align } : {};
      },
    },
  ],
  toDOM(node) {
    return ['p', { class: node.attrs.align }, 0];
  },
};
