import { get } from 'lodash';
import baseDataProvider from './base';

const resourceHandlers = {};
const registerResourceHandler = (resource, method, handler) => {
  resourceHandlers[resource] = {
    ...(resourceHandlers[resource] || {}),
    [method]: handler,
  };
};

const callMethod = (method, resource, params) => {
  method = get(resourceHandlers, [resource, method], baseDataProvider[method]);
  return method(resource, params);
};

// entry point for data provider
// it eathier calls resource specific data provider (if registered)
// or calls default one
const dataProvider = {
  getList: (resource, params) => callMethod('getList', resource, params),
  getOne: (resource, params) => callMethod('getOne', resource, params),
  getMany: (resource, params) => callMethod('getMany', resource, params),
  getManyReference: (resource, params) => callMethod('getManyReference', resource, params),
  update: (resource, params) => callMethod('update', resource, params),
  updateMany: (resource, params) => callMethod('updateMany', resource, params),
  create: (resource, params) => callMethod('create', resource, params),
  delete: (resource, params) => callMethod('delete', resource, params),
  deleteMany: (resource, params) => callMethod('deleteMany', resource, params),
};

export default dataProvider;
export { registerResourceHandler };
