import React, { useRef } from 'react';
import {
  Datagrid,
  List,
  TextField,
  Edit,
  TextInput,
  TabbedForm,
  FormTab,
  EditButton,
  useEditController,
  Create,
} from 'react-admin';
import UploadAvatar from './components/uploadAvatar';
import { dataURLToBlob } from './utils/filesHelper';
import isString from 'lodash/isString';
import { httpClient, apiUrl } from './api/dataProvider/base';

const transformForm = async (record, data) => {
  const formData = new FormData();
  for (let key in data) {
    if (key !== 'avatar' && key !== 'removeAvatar') {
      formData.set(`user[${key}]`, data[key]);
    }
  }

  if (data.removeAvatar === true) {
    const removeAvatarUrl = new URL(`${apiUrl}/admin/users/${record.id}/avatar`);
    await httpClient(removeAvatarUrl, {
      method: 'DELETE',
    });
  }

  // if avatar is string & it's new => it's data url in base64
  if (isString(data.avatar) && record.avatar !== data.avatar) {
    const avatarBlob = dataURLToBlob(data.avatar);
    formData.append('user[avatar]', avatarBlob, 'avatar');
  }

  return formData;
};

export const UserList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" label="User ID" />
      <TextField source="email" />
      <TextField source="first_name" />
      <TextField source="last_name" />
      <EditButton />
    </Datagrid>
  </List>
);

export const UserEdit = props => {
  const { record } = useEditController(props);
  const recordRef = useRef();
  recordRef.current = record;

  return (
    <Edit {...props} transform={data => transformForm(recordRef.current, data)}>
      <TabbedForm>
        <FormTab label="profile">
          <TextInput source="email" />
          <TextInput source="first_name" />
          <TextInput source="last_name" />
        </FormTab>
        <FormTab label="avatar">
          <UploadAvatar source="avatar" removeAvatarSource="removeAvatar" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export const UserCreate = props => (
  <Create {...props} transform={transformForm.bind(this, {})}>
    <TabbedForm>
      <FormTab label="profile">
        <TextInput source="email" />
        <TextInput source="first_name" />
        <TextInput source="last_name" />
      </FormTab>
      <FormTab label="avatar">
        <UploadAvatar source="avatar" removeAvatarSource="removeAvatar" />
      </FormTab>
    </TabbedForm>
  </Create>
);
