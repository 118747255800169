import { apiUrl, httpClient } from './base';
import baseDataProvider, { registerResourceHandler } from './proxy';

const experts = {
  getList: (_resource, params) => baseDataProvider.getList('admin/users/experts', params),
  getMany: (_resource, params) => baseDataProvider.getMany('admin/users/experts', params),
  getOne: (_resource, params) => baseDataProvider.getOne('admin/users/experts', params),
  create: (_resource, params) =>
    httpClient(`${apiUrl}/admin/users/experts`, {
      method: 'POST',
      body: JSON.stringify({ expert: params.data }),
    }).then(({ json }) => ({ data: json })),
  update: (_resource, params) =>
    httpClient(`${apiUrl}/admin/users/experts/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify({ expert: params.data }),
    }).then(({ json }) => ({ data: json })),
  delete: (_resource, params) => baseDataProvider.delete('admin/users/experts', params),
  deleteMany: (_resource, params) => baseDataProvider.deleteMany('admin/users/experts', params),
};

registerResourceHandler('experts', 'getList', experts.getList);
registerResourceHandler('experts', 'getMany', experts.getMany);
registerResourceHandler('experts', 'getOne', experts.getOne);
registerResourceHandler('experts', 'update', experts.update);
registerResourceHandler('experts', 'create', experts.create);
registerResourceHandler('experts', 'delete', experts.delete);
registerResourceHandler('experts', 'deleteMany', experts.deleteMany);
