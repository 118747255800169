import React from 'react';
import { BooleanInput, NumberInput } from 'react-admin';
import { useWatch } from 'react-hook-form';
import Grid from '@mui/material/Grid';

const EditorsPickInput = props => {
  const editorsPickPosition = useWatch({ name: 'editors_pick.position' });
  const editorsPickEnabled = useWatch({
    name: 'editors_pick.enabled',
    defaultValue: !!editorsPickPosition,
  });

  return (
    <Grid item xs={12}>
      <BooleanInput
        fullWidth
        label={`Editor's pick ${editorsPickEnabled ? 'включен' : 'выключен'}`}
        source="editors_pick.enabled"
        defaultValue={!!editorsPickPosition}
      />
      {editorsPickEnabled ? <NumberInput source="editors_pick.position" /> : null}
    </Grid>
  );
};

export default EditorsPickInput;
