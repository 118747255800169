import React from 'react';
import { Admin, Resource, CustomRoutes } from 'react-admin';
import { Route } from 'react-router-dom';
import { ProjectList, ProjectEdit, ProjectCreate } from './projects';
import { ProjectCategoryList } from './projectCategories';
import { AuthorList, AuthorEdit, AuthorCreate } from './authors';
import { SocietyList, SocietyEdit, SocietyCreate } from './communities';
import { UserList, UserEdit, UserCreate } from './users';
import { ExpertList, ExpertEdit, ExpertCreate } from './experts';
import { ExpertStatusRequestList, ExpertStatusRequestShow } from './expertStatusRequests';
import { SurveyList, SurveyEdit, SurveyCreate } from './surveys';
import authProvider from './authProvider';
import dataProvider from './api/dataProvider';
import { ProjectReviewList, ProjectReviewEdit, ProjectReviewCreate } from './projectReviews';
import { ArticleList, ArticleEdit, ArticleCreate } from './articles';
import { ArticlesPageLayoutEdit } from './articlesPageLayout';
import Layout from './Layout';

const App = () => (
  <Admin
    title="Bank of Ideas Admin"
    layout={Layout}
    dataProvider={dataProvider}
    authProvider={authProvider}
  >
    <Resource name="projects" list={ProjectList} edit={ProjectEdit} create={ProjectCreate} />
    <Resource name="project_categories" list={ProjectCategoryList} />
    <Resource name="authors" list={AuthorList} edit={AuthorEdit} create={AuthorCreate} />
    <Resource name="communities" list={SocietyList} edit={SocietyEdit} create={SocietyCreate} />
    <Resource name="users" list={UserList} edit={UserEdit} create={UserCreate} />
    <Resource
      name="experts"
      options={{ label: 'Experts' }}
      list={ExpertList}
      edit={ExpertEdit}
      create={ExpertCreate}
    />
    <Resource
      name="expert_status_requests"
      options={{ label: 'Expert Status Requests' }}
      list={ExpertStatusRequestList}
      show={ExpertStatusRequestShow}
    />
    <Resource name="surveys" list={SurveyList} edit={SurveyEdit} create={SurveyCreate} />
    <Resource
      name="project_reviews"
      list={ProjectReviewList}
      edit={ProjectReviewEdit}
      create={ProjectReviewCreate}
    />
    <Resource name="articles" list={ArticleList} edit={ArticleEdit} create={ArticleCreate} />
    <Resource name="project_ownerships" />
    <Resource name="article_ownerships" />

    <CustomRoutes>
      <Route path="/articles_page_layout" element={<ArticlesPageLayoutEdit />} />
    </CustomRoutes>
  </Admin>
);

export default App;
