import { httpClient, apiUrl } from './base';
import baseDataProvider, { registerResourceHandler } from './proxy';

const projectReviews = {
  create: (_resource, params) =>
    httpClient(`${apiUrl}/admin/project_reviews`, {
      method: 'POST',
      body: JSON.stringify({
        project_review: params.data,
      }),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    })),
  update: (_resource, params) =>
    httpClient(`${apiUrl}/admin/project_reviews/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify({
        project_review: params.data,
      }),
    }).then(({ json }) => ({ data: json })),
  delete: (_resource, params) => baseDataProvider.delete('admin/project_reviews', params),
  deleteMany: (_resource, params) => baseDataProvider.deleteMany('admin/project_reviews', params),
};

registerResourceHandler('project_reviews', 'create', projectReviews.create);
registerResourceHandler('project_reviews', 'update', projectReviews.update);
registerResourceHandler('project_reviews', 'delete', projectReviews.delete);
registerResourceHandler('project_reviews', 'deleteMany', projectReviews.deleteMany);
