import { TextAlign, TextAlignValues } from '../common';

export const HeadingNodeSpec = {
  attrs: {
    level: { default: 1 },
    align: { default: TextAlign.Left },
  },
  content: 'inline*',
  group: 'block',
  defining: true,
  parseDOM: [
    {
      tag: 'h1, h2, h3, h4, h5, h6',
      getAttrs: dom => {
        const level = +dom.tagName[1];
        const align = TextAlignValues.find(value => dom.classList.contains(value));
        return align ? { level, align } : { level };
      },
    },
  ],
  toDOM(node) {
    return ['h' + node.attrs.level, { class: node.attrs.align }, 0];
  },
};
