import baseDataProvider, { registerResourceHandler } from './proxy';
import { apiUrl, httpClient, handleRailsError } from './base';

const users = {
  getList: (_resource, params) => baseDataProvider.getList('admin/users', params),
  getMany: (_resource, params) => baseDataProvider.getMany('admin/users', params),
  getOne: (_resource, params) => baseDataProvider.getOne('admin/users', params),
  // we use form data here
  create: (_resource, params) =>
    httpClient(`${apiUrl}/admin/users`, {
      method: 'POST',
      body: params.data,
    })
      .then(({ json }) => ({ data: json }))
      .catch(handleRailsError),
  // we use form data here
  update: (_resource, params) =>
    httpClient(`${apiUrl}/admin/users/${params.id}`, {
      method: 'PUT',
      body: params.data,
    }).then(({ json }) => ({ data: json })),
  delete: (_resource, params) => baseDataProvider.delete('admin/users', params),
  deleteMany: (_resource, params) => baseDataProvider.deleteMany('admin/users', params),
};

registerResourceHandler('users', 'getList', users.getList);
registerResourceHandler('users', 'getMany', users.getMany);
registerResourceHandler('users', 'getOne', users.getOne);
registerResourceHandler('users', 'create', users.create);
registerResourceHandler('users', 'update', users.update);
registerResourceHandler('users', 'delete', users.delete);
registerResourceHandler('users', 'deleteMany', users.deleteMany);
