import React, { useRef, useEffect } from 'react';
import Frame, { FrameContextConsumer } from 'react-frame-component';
import { useWatch } from 'react-hook-form';
import './styles.css';
/* eslint import/no-webpack-loader-syntax: off */
import css from '!!raw-loader!./preview.css';
import tippy from 'tippy.js';
import tippyStyles from '!!raw-loader!tippy.js/dist/tippy.css'; // optional for styling

const DescriptionPreview = props => {
  let { source } = props;
  source = source || 'description';

  const description = useWatch({ name: source });

  const iframeRef = useRef();
  useEffect(() => {
    iframeRef.current.srcdoc = `<div class="rte-content">${description}</div>`;
  }, [description]);

  let iframeDocumentRef = useRef();
  useEffect(() => {
    if (!iframeDocumentRef.current) {
      return;
    }

    tippy(iframeDocumentRef.current?.querySelectorAll('.tooltip'), {
      content: node => {
        const tooltip = document.createElement('div');
        tooltip.style = 'word-break: break-word'; // if you're going to add more styles - move it into class please
        tooltip.innerText = node.getAttribute('data-text');

        return tooltip;
      },
      maxWidth: 'none',
      appendTo: 'parent',
    });
  }, [iframeDocumentRef.current]);

  return (
    <Frame
      head={
        <>
          <style>{tippyStyles}</style>
          <style>{css}</style>
        </>
      }
      className="description-preview"
      ref={iframeRef}
    >
      <FrameContextConsumer>
        {({ document }) => {
          iframeDocumentRef.current = document;
        }}
      </FrameContextConsumer>
    </Frame>
  );
};

export default DescriptionPreview;
