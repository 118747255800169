import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import { useRecordContext } from 'react-admin';
import { useController } from 'react-hook-form';
import { useQuery } from 'react-query';
import { Autocomplete, TextField } from '@mui/material';
import dataProvider from '../api/dataProvider';

export const ProjectAuthorsArrayInput = ({ value, onChange }) => {
  const [selectedAuthors, setSelectedAuthors] = useState([]);
  let { data: res } = useQuery('experts', () => dataProvider.getList('experts'));
  let { data: authors } = res || {};
  authors = authors || [];

  const record = useRecordContext() || {};

  useEffect(() => {
    setSelectedAuthors(record.authors || []);
  }, [record.authors]);

  const handleChange = (event, value) => {
    setSelectedAuthors(value || []);
    onChange((value || []).map(v => v.id));
  };

  return (
    <FormControl fullWidth>
      <Autocomplete
        multiple
        options={authors}
        value={selectedAuthors}
        getOptionLabel={author => `${author.first_name} ${author.last_name}`}
        renderInput={params => <TextField {...params} label="Authors" />}
        onChange={handleChange}
      />
    </FormControl>
  );
};

export const ProjectAuthorsArrayInputField = () => {
  const input = useController({ name: 'author_ids' });

  return <ProjectAuthorsArrayInput {...input.field} />;
};

export default ProjectAuthorsArrayInputField;
