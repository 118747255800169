import React, { useState, useRef } from 'react';
import { useController } from 'react-hook-form';
import './styles.css';
import Button from '@mui/material/Button';
import { Labeled } from 'react-admin';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import Dialog from '@mui/material/Dialog';

const Preview = ({ src, onCropperInitialized, onCrop }) => {
  return (
    <Dialog open>
      <div className="image-uploader__preview">
        <Cropper src={src} viewMode={2} onInitialized={onCropperInitialized} />
        <Button variant="contained" onClick={onCrop}>
          Crop
        </Button>
      </div>
    </Dialog>
  );
};

const ImageUploaderInput = ({ source, withCropper }) => {
  const input = useController({ name: source });
  const [isEditing, setIsEditing] = useState(false);
  const [previewSrc, setPreviewSrs] = useState();
  const cropperRef = useRef();

  const deleteImage = () => input.field.onChange(null);

  const uploadImage = e => {
    if (e.target.files.length === 0) {
      return;
    }

    const reader = new FileReader();
    reader.onload = e => {
      if (!e.target.result) {
        return;
      }

      if (withCropper) {
        setIsEditing(true);
        setPreviewSrs(e.target.result);
      } else {
        input.field.onChange(e.target.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const onCropperInitialized = cropper => (cropperRef.current = cropper);

  const onCrop = () => {
    const cropper = cropperRef.current;
    const dataUrl = cropper.getCroppedCanvas().toDataURL();

    input.field.onChange(dataUrl);
    setIsEditing(false);
  };

  return (
    <div className="image-uploader">
      {isEditing && (
        <Preview src={previewSrc} onCropperInitialized={onCropperInitialized} onCrop={onCrop} />
      )}

      <div className="image-uploader__preview">
        <img src={input.field.value} width="100%" />
      </div>

      {!input.field.value && (
        <Button variant="contained" component="label">
          Upload
          <input type="file" accept="image/*" onChange={uploadImage} hidden />
        </Button>
      )}
      {input.field.value && (
        <Button variant="outlined" onClick={deleteImage}>
          Delete
        </Button>
      )}
    </div>
  );
};

const LabeledInput = params => (
  <Labeled fullWidth>
    <ImageUploaderInput {...params} />
  </Labeled>
);

export default LabeledInput;
