import { httpClient } from '../dataProvider/base';
import { dataURLToBlob } from '../../utils/filesHelper';
import get from 'lodash/get';

class AttachedImageHandler {
  constructor({ resource, resourcesUrl }) {
    this.resource = resource;
    this.resourcesUrl = resourcesUrl;
  }

  /**
   *
   * @param {Object} params
   * @param {string} image - image field name
   * @param {Object} options - additional options, like deleteUrl
   * @returns
   */
  handle = (params, image, options = {}) => {
    const { deleteUrl } = options;
    const formAttribute = `${this.resource}[${image}_url]`;
    const objAttribute = `${image}_url`;

    const img = params.data.get(formAttribute);
    params.data.delete(formAttribute);

    const previousImg = get(params, ['previousData', objAttribute]);

    if (!!previousImg && !img) {
      // if image was deleted
      deleteUrl ||= `${this.resourcesUrl}/${params.id}/${image}`;
      return httpClient(deleteUrl, { method: 'DELETE' });
    } else if (!!img && previousImg !== img) {
      // if image was replaced

      const imgBlob = dataURLToBlob(img);
      params.data.set(`${this.resource}[${image}]`, imgBlob, image);
    } else if (!previousImg && !!img) {
      // if image was added

      const imgBlob = dataURLToBlob(img);
      params.data.set(`${this.resource}[${image}]`, imgBlob, image);
    }
  };
}

export default AttachedImageHandler;
