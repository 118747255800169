import dataProvider from './proxy';
import './experts';
import './users';
import './projectReviews';
import './surveys';
import './expertStatusRequests';
import './communities';
import './articles';
import './articlesLayoutBlocks';
import './projects';

export default dataProvider;
