import validUrl from 'valid-url';

const urlValidator = value => {
  if (!value) {
    return undefined;
  }

  return validUrl.isUri(value) ? undefined : 'Must be valid url';
};

export { urlValidator };
