import { registerResourceHandler } from './proxy';
import { apiUrl, httpClient } from './base';
import AttachedImageHandler from '../helpers/attachedImageHandler';

const imageHandler = new AttachedImageHandler({
  resource: 'articles_layout_block',
  resourcesUrl: `${apiUrl}/articles_layout_blocks`,
});

const articlesLayoutBlocks = {
  // form data
  create: (_resource, params) => {
    imageHandler.handle(params, 'preview_img');

    return httpClient(`${apiUrl}/articles_layout_blocks`, {
      method: 'POST',
      body: params.data,
    }).then(({ json }) => ({ data: json }));
  },
  // form data
  update: async (_resource, params) => {
    imageHandler.handle(params, 'preview_img', {
      deleteUrl: `${apiUrl}/articles_layout_blocks/${params.id}/preview_image`,
    });

    try {
      const { json } = await httpClient(`${apiUrl}/articles_layout_blocks/${params.id}`, {
        method: 'PUT',
        body: params.data,
      });

      return { data: json };
    } catch (error) {
      throw new Error(
        Object.entries(error.body)
          .map(([key, value]) => `${key}: ${value.join('; ')}`)
          .join('\n')
      );
    }
  },
};

registerResourceHandler('articles_layout_blocks', 'create', articlesLayoutBlocks.create);
registerResourceHandler('articles_layout_blocks', 'update', articlesLayoutBlocks.update);
