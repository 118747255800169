export const TextAlign = {
  Left: 'text-align-left',
  Center: 'text-align-center',
  Right: 'text-align-right',
};

export const TextAlignValues = Object.values(TextAlign);

/// Icons

const createIcon = name => {
  const icon = document.createElement('i');
  icon.className = `fa-solid fa-${name}`;
  return icon;
};

export const iconLeft = createIcon('align-left');

export const iconCenter = createIcon('align-center');

export const iconRight = createIcon('align-right');

export const iconHighlight = createIcon('highlighter');

export const iconInfo = createIcon('circle-info');

export const iconUnderline = createIcon('underline');

export const RTESelectionChangeEventName = 'rteselectionchange';
