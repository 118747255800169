import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  FunctionField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  Create,
} from 'react-admin';
import FiveStarsRankgingInput from './components/fiveStarsRankingInput';

export const ProjectReviewList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="abstract" />
      <TextField source="rank" />
      <ReferenceField source="author_id" reference="experts">
        <FunctionField
          label="Author"
          render={record => `${record.first_name} ${record.last_name}`}
        />
      </ReferenceField>
      <ReferenceField source="project_id" reference="projects">
        <TextField source="title" />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
);

export const ProjectReviewEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <FiveStarsRankgingInput source="rank" />
      <TextInput source="abstract" label="Опишите проект в 5-10 словах" />
      <TextInput source="overall_assessment" label="Общая оценка проекта" multiline />
      <TextInput source="pros" label="Сильные стороны" multiline />
      <TextInput source="cons" label="Слабые стороны" multiline />
      <TextInput
        source="other_comments"
        label="Прочие комментарии, замечания, предложения"
        multiline
      />
      <TextInput
        source="free_form"
        label="Свободная форма (если есть будет использована вместо предыдущих полей)"
        multiline
      />
      <ReferenceInput label="Автор" source="author_id" reference="experts">
        <AutocompleteInput
          optionText={r => `${r.first_name} ${r.last_name} - ${r.position} @ ${r.organization}`}
          sx={{ width: '191px' }}
        />
      </ReferenceInput>
      <ReferenceInput label="Проект" source="project_id" reference="projects">
        <SelectInput optionText={r => r.title} sx={{ width: '191px' }} />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export const ProjectReviewCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <FiveStarsRankgingInput source="rank" />
      <TextInput source="abstract" label="Опишите проект в 5-10 словах" />
      <TextInput source="overall_assessment" label="Общая оценка проекта" multiline />
      <TextInput source="pros" label="Сильные стороны" multiline />
      <TextInput source="cons" label="Слабые стороны" multiline />
      <TextInput
        source="other_comments"
        label="Прочие комментарии, замечания, предложения"
        multiline
      />
      <TextInput
        source="free_form"
        label="Свободная форма (если есть будет использована вместо предыдущих полей)"
        multiline
      />
      <ReferenceInput label="Автор" source="author_id" reference="experts">
        <AutocompleteInput
          optionText={r => `${r.first_name} ${r.last_name} - ${r.position} @ ${r.organization}`}
          sx={{ width: '191px' }}
        />
      </ReferenceInput>
      <ReferenceInput label="Проект" source="project_id" reference="projects">
        <SelectInput optionText={r => r.title} sx={{ width: '191px' }} />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
