export const CustomCodeNodeSpec = {
  atom: true,
  inline: false,
  group: 'block',
  attrs: {
    customCode: { default: '' },
  },
  toDOM(node) {
    let div = document.createElement('div');
    div.innerHTML = node.attrs.customCode;
    div.setAttribute('data-custom-code', 'true');
    div.classList.add('custom-code');

    return div;
  },
  parseDOM: [
    {
      tag: 'div',
      getAttrs: dom => {
        const { customCode } = dom.dataset;
        return !!customCode ? { customCode: dom.innerHTML } : false;
      },
    },
  ],
};

export class CustomCodeView {
  dom = document.createElement('iframe');
  resizeObserver = new ResizeObserver(this.resizeObserverCallback.bind(this));
  observerTarget;

  constructor(node, view, getPos) {
    this.dom.setAttribute('data-custom-code', node.attrs.customCode);
    this.dom.classList.add('custom-code');

    this.dom.addEventListener('load', e => {
      const doc = this.dom.contentDocument;
      doc.open();
      doc.write(node.attrs.customCode);
      doc.close();

      const style = document.createElement('style');
      style.textContent = 'body { margin: 0; }';
      doc.body.append(style);

      this.observerTarget = doc.children[0];
      this.resizeObserver.observe(this.observerTarget, { box: 'border-box' });
    });

    // Code below left for div case

    // this.dom = document.createElement("div");
    // this.dom.setAttribute("data-custom-code", "true");
    // this.dom.classList.add("custom-code");
    // this.dom.innerHTML = node.attrs.customCode;

    // Array.from(this.dom.querySelectorAll("script")).forEach((oldScript) => {
    //   const newScript = document.createElement("script");
    //   Array.from(oldScript.attributes).forEach((attr) =>
    //     newScript.setAttribute(attr.name, attr.value)
    //   );
    //   newScript.appendChild(document.createTextNode(oldScript.innerHTML));
    //   oldScript.parentNode.replaceChild(newScript, oldScript);
    // });
  }

  /**
   * @param {ResizeObserverEntry[]} e
   */
  resizeObserverCallback(e) {
    const computedHeight = Math.ceil(e[0].contentRect.height) + 'px';
    if (this.dom.style.height !== computedHeight) {
      this.dom.style.height = computedHeight;
    }
  }

  stopEvent() {
    return true;
  }

  update() {
    return true;
  }

  destroy() {
    this.resizeObserver.unobserve(this.observerTarget);
  }
}
