import { registerResourceHandler } from './proxy';
import baseDataProvider from './base';
import omit from 'lodash/omit';

const modifyFields = project => {
  project.img_src = { src: project.img_src };
  project.obligatory_project_review_attributes = project.project_reviews?.find(
    pr => !!pr.obligatory
  );
  project.editors_pick_position = project.editors_pick?.position;
};

const projects = {
  getList: async (_resource, params) => {
    params = {
      ...params,
      adminRoute: true,
    };
    const result = await baseDataProvider.getList('projects', params);
    result.data.forEach(modifyFields);

    return result;
  },
  getMany: async (_resource, params) => {
    params = {
      ...params,
      adminRoute: true,
    };
    const result = await baseDataProvider.getMany('projects', params);
    result.data.forEach(modifyFields);

    return result;
  },
  getOne: async (_resource, params) => {
    params = {
      ...params,
      adminRoute: true,
    };
    const result = await baseDataProvider.getOne('projects', params);
    modifyFields(result.data);

    return result;
  },
  create: (_resource, params) => {
    let { data: project } = params;

    // if all obligatory review fields are falsy => omit it
    if (Object.values(project.obligatory_project_review_attributes).every(v => !v)) {
      project = omit(project, 'obligatory_project_review_attributes');
    }

    return baseDataProvider.create('projects', { ...params, data: project, adminRoute: true });
  },
  update: (_resource, params) => {
    let { data: project } = params;

    // if all obligatory review fields are falsy => omit it
    if (Object.values(project.obligatory_project_review_attributes).every(v => !v)) {
      project = omit(project, 'obligatory_project_review_attributes');
    }

    return baseDataProvider.update('projects', { ...params, data: project, adminRoute: true });
  },
};

registerResourceHandler('projects', 'getList', projects.getList);
registerResourceHandler('projects', 'getMany', projects.getMany);
registerResourceHandler('projects', 'getOne', projects.getOne);
registerResourceHandler('projects', 'create', projects.create);
registerResourceHandler('projects', 'update', projects.update);
