import React, { useState, useEffect } from 'react';
import {
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  useRecordContext,
  SingleFieldList,
  TabbedForm,
  SelectInput,
  Create,
  ListContextProvider,
  useListContext,
  useGetMany,
  FormTab,
  TextInput,
  List,
  Edit,
  useNotify,
  useRedirect,
} from 'react-admin';
import { useController } from 'react-hook-form';
import ArticleAuthorsArrayInputField from './articles/ArticleAuthorsArrayInput';
import DescriptionPreview from './components/descriptionPreview';
import { serialize } from 'object-to-formdata';
import ImageUploaderInput from './components/imageUploaderInput';
import RTEInput from './components/rteInput';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useQueryClient, useQuery } from 'react-query';
import dataProvider from './api/dataProvider';
import { ProjectCategoryInput } from './projects/ProjectCategoryInput';
import { AutosaveToolbar } from './components/autosaveToolbar';

const BodyInput = RTEInput;

const projectCategoryLink = (record, reference) =>
  `/${reference}?selectedNodeId=${record.project_category_id}`;

const AuthorField = ({ render, ...rest }) => {
  const record = useRecordContext(rest);
  const label = render(record);

  return <Chip label={label} sx={{ margin: '5px' }} />;
};

const ArticleAuthors = props => {
  const record = useRecordContext(props);
  const { authors, articleOwnerships, authorsLoaded } = props;

  const [articleAuthors, setArticleAuthors] = useState([]);
  useEffect(() => {
    if (!authorsLoaded || !articleOwnerships) {
      return;
    }

    let articleAuthors = [];
    articleOwnerships.forEach(ao => {
      if (ao.article_id === record.id) {
        const author = authors.find(author => author.id === ao.author_id);
        if (!author) {
          return;
        }

        articleAuthors = [...articleAuthors, author];
      }
    });

    setArticleAuthors(articleAuthors);
  }, [authors, authorsLoaded, articleOwnerships, record.id]);

  const listContext = {
    loaded: authorsLoaded,
    data: articleAuthors,
    basePath: 'authors',
  };

  return (
    <ListContextProvider value={listContext}>
      <SingleFieldList>
        <AuthorField render={r => `${r.first_name} ${r.last_name}`} />
      </SingleFieldList>
    </ListContextProvider>
  );
};

const ARTICLE_THEME_TYPES = {
  experts: 'experts',
  new_blood: 'new_blood',
};

const ThemeTypeSwitch = () => {
  const input = useController({ name: 'theme_type' });

  const onChange = checked => {
    input.field.onChange(checked ? ARTICLE_THEME_TYPES.new_blood : ARTICLE_THEME_TYPES.experts);
  };

  const control = (
    <Switch onChange={onChange} checked={input.field.value === ARTICLE_THEME_TYPES.new_blood} />
  );

  return <FormControlLabel control={control} label="New Blood" />;
};

const transformForm = async data => {
  const options = { allowEmptyArrays: true };
  const formData = serialize(data, options, null, 'article');

  return formData;
};

const ArticleListGrid = props => {
  let { data: articles } = useListContext();
  articles = articles || [];
  const articleIds = articles.map(article => article.id);

  let { data: articleOwnershipsRes } = useQuery(
    ['article_ownerships', 'getList'],
    () => dataProvider.getList('article_ownerships', { filter: { article_id: articleIds } }),
    { enabled: articles.length > 0 }
  );
  let { data: articleOwnerships } = articleOwnershipsRes || {};
  articleOwnerships = articleOwnerships || [];

  const authorIds = articleOwnerships.map(ao => ao.author_id);
  let { data: authors, isLoading: authorsLoading } = useGetMany(
    'users',
    { ids: authorIds },
    { enabled: authorIds.length > 0 }
  );
  authors = authors || [];

  const authorsLoaded = !authorsLoading && authors.length > 0;

  return (
    <Datagrid {...props}>
      <TextField source="title" />
      <TextField source="abstract" className="articles__abstract" />
      <ReferenceField
        source="project_category_id"
        reference="project_categories"
        link={projectCategoryLink}
      >
        <TextField source="name" />
      </ReferenceField>
      <ArticleAuthors
        source="users"
        label="Authors"
        articleOwnerships={articleOwnerships}
        authors={authors}
        authorsLoaded={authorsLoaded}
        sortable={false}
      />
      <TextField source="status" />
      <EditButton />
    </Datagrid>
  );
};

const ArticleForm = props => {
  return (
    <TabbedForm {...props} toolbar={<AutosaveToolbar />}>
      <FormTab label="summary">
        <Grid container width={{ xs: '100%', sm: 600 }}>
          <TextInput fullWidth source="title" />
          <TextInput fullWidth source="abstract" multiline />
          <ProjectCategoryInput />
          <ArticleAuthorsArrayInputField />
          <SelectInput
            fullWidth
            source="status"
            choices={[
              { id: 'active', name: 'Active' },
              { id: 'inactive', name: 'Inactive' },
            ]}
          />
          <ThemeTypeSwitch />
          <TextInput fullWidth source="url_slug" />
          <TextInput fullWidth source="url_slug_alternative" />
          <ImageUploaderInput source="preview_img_url" withCropper />
          <ImageUploaderInput source="header_img_url" withCropper />
        </Grid>
      </FormTab>
      <FormTab label="body">
        <BodyInput htmlSource="body" />
      </FormTab>
      <FormTab label="body preview">
        <DescriptionPreview source="body" />
      </FormTab>
    </TabbedForm>
  );
};

export const ArticleList = props => (
  <List {...props}>
    <ArticleListGrid />
  </List>
);

export const ArticleEdit = props => {
  const queryClient = useQueryClient();
  const notify = useNotify();
  const redirect = useRedirect();

  const invalidateCache = data => {
    queryClient.invalidateQueries(['article_ownerships', 'getList']);
    notify('ra.notification.updated', {
      messageArgs: { smart_count: 1 },
    });
    // redirect('/articles');
  };

  return (
    <Edit
      {...props}
      redirect={false}
      mutationMode="optimistic"
      transform={transformForm}
      mutationOptions={{ onSettled: invalidateCache }}
    >
      <ArticleForm />
    </Edit>
  );
};

export const ArticleCreate = props => (
  <Create {...props} redirect={false} transform={transformForm}>
    <ArticleForm />
  </Create>
);
