import { registerResourceHandler } from './proxy';
import { apiUrl, httpClient } from './base';
import AttachedImageHandler from '../helpers/attachedImageHandler';

const imageHandler = new AttachedImageHandler({
  resource: 'community',
  resourcesUrl: `${apiUrl}/communities`,
});

const communities = {
  // form data
  create: (_resource, params) => {
    imageHandler.handle(params, 'image');

    return httpClient(`${apiUrl}/communities`, {
      method: 'POST',
      body: params.data,
    }).then(({ json }) => ({ data: json }));
  },
  // form data
  update: async (_resource, params) => {
    imageHandler.handle(params, 'image');

    try {
      const { json } = await httpClient(`${apiUrl}/communities/${params.id}`, {
        method: 'PUT',
        body: params.data,
      });

      return { data: json };
    } catch (error) {
      if (error.status === 500) {
        throw new Error(error.message);
      }

      throw new Error(
        Object.entries(error.body)
          .map(([key, value]) => `${key}: ${value.join('; ')}`)
          .join('\n')
      );
    }
  },
};

registerResourceHandler('communities', 'create', communities.create);
registerResourceHandler('communities', 'update', communities.update);
