export const TooltipMarkSpec = {
  group: 'inline',
  inclusive: false,
  attrs: {
    text: { default: '' },
  },
  toDOM(node) {
    return [
      'span',
      {
        'data-text': node.attrs.text,
        class: 'tooltip',
      },
      0,
    ];
  },
  parseDOM: [
    {
      tag: 'span.tooltip',
      getAttrs: dom => {
        return dom.classList.contains('tooltip')
          ? {
              text: dom.dataset.text || '',
            }
          : false;
      },
    },
  ],
};
