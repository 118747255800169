import { registerResourceHandler } from './proxy';
import { apiUrl, httpClient } from './base';
import AttachedImageHandler from '../helpers/attachedImageHandler';

const imageHandler = new AttachedImageHandler({
  resource: 'survey',
  resourcesUrl: `${apiUrl}/surveys`,
});

const surveys = {
  // we use form data here
  create: (_resource, params) => {
    imageHandler.handle(params, 'image');

    return httpClient(`${apiUrl}/surveys`, {
      method: 'POST',
      body: params.data,
    }).then(({ json }) => ({ data: json }));
  },
  // we use form data here
  update: async (_resource, params) => {
    imageHandler.handle(params, 'image');

    try {
      const { json } = await httpClient(`${apiUrl}/surveys/${params.id}`, {
        method: 'PUT',
        body: params.data,
      });

      return { data: json };
    } catch (error) {
      throw new Error(
        Object.entries(error.body)
          .map(([key, value]) => `${key}: ${value.join('; ')}`)
          .join('\n')
      );
    }
  },
};

registerResourceHandler('surveys', 'create', surveys.create);
registerResourceHandler('surveys', 'update', surveys.update);
