import React, { useState, useEffect } from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  Edit,
  TextInput,
  ReferenceInput,
  SelectInput,
  Create,
  ListContextProvider,
  SingleFieldList,
  TabbedForm,
  FormTab,
  useListContext,
  useRecordContext,
  useGetMany,
  ImageInput,
  ImageField,
  useNotify,
  useRedirect,
} from 'react-admin';
import { get } from 'lodash';
import validUrl from 'valid-url';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import { useQueryClient, useQuery } from 'react-query';
import ProjectAuthorsArrayInputField from './projects/ProjectAuthorsArrayInput';
import { ProjectCategoryInput } from './projects/ProjectCategoryInput';
import RTEInput from './components/rteInput';
import DescriptionPreview from './components/descriptionPreview';
import FiveStarsRankingInput from './components/fiveStarsRankingInput';
import EditorsPickInput from './components/editorsPickInput';
import { uploadAsset } from './utils/filesHelper';
import dataProvider from './api/dataProvider';

const DescriptionInput = RTEInput;

const projectCategoryLink = (category, reference) => `/${reference}?selectedNodeId=${category.id}`;

const AuthorField = ({ render, ...rest }) => {
  const record = useRecordContext(rest);
  const label = render(record);

  return <Chip label={label} sx={{ margin: '5px' }} />;
};

const ProjectAuthors = props => {
  const record = useRecordContext(props);
  const { authors, projectOwnerships, authorsLoaded } = props;

  const [projectAuthors, setProjectAuthors] = useState([]);
  useEffect(() => {
    if (!authorsLoaded || !projectOwnerships) {
      return;
    }

    let projectAuthors = [];
    projectOwnerships.forEach(po => {
      if (po.project_id === record.id) {
        const author = authors.find(a => a.id === po.author_id);
        if (!author) {
          return;
        }

        projectAuthors = [...projectAuthors, author];
      }
    });

    setProjectAuthors(projectAuthors);
  }, [authors, authorsLoaded, projectOwnerships, record.id]);

  const listContext = {
    loaded: props.authorsLoaded,
    data: projectAuthors,
    basePath: 'authors',
  };

  return (
    <ListContextProvider value={listContext}>
      <SingleFieldList>
        <AuthorField render={r => `${r.first_name} ${r.last_name}`} />
      </SingleFieldList>
    </ListContextProvider>
  );
};

const transformForm = async data => {
  data['project_map_attributes'] = {
    id: get(data, ['project_map', 'id']),
    affected_topics: get(data, ['project_map', 'affected_topics']),
    circumstances_needed: get(data, ['project_map', 'circumstances_needed']),
    developer: get(data, ['project_map', 'developer']),
    resources_needed: get(data, ['project_map', 'resources_needed']),
    summary: get(data, ['project_map', 'summary']),
  };

  delete data['project_map'];

  data['editors_pick_position'] = data.editors_pick.enabled ? data.editors_pick.position : null;
  delete data['editors_pick'];

  if (data['img_src']) {
    if (data['img_src'].rawFile) {
      const file = data['img_src'].rawFile;
      const publicUrl = await uploadAsset(file);
      data['img_src'] = publicUrl;
    } else if (data['img_src'].src) {
      data['img_src'] = data['img_src'].src;
    }
  }

  return data;
};

const urlValidation = value => {
  if (!value) {
    return undefined;
  }

  return validUrl.isUri(value) ? undefined : 'Must be valid url';
};

const projectValidation = values => {
  const errors = {};
  let { obligatory_project_review_attributes } = values;
  obligatory_project_review_attributes = obligatory_project_review_attributes || {};
  const {
    abstract,
    pros_cons,
    expected_effort_correctness,
    realism,
    required_resources_correctness,
    other_characteristics,
    author_id,
  } = obligatory_project_review_attributes;

  if (
    (!!abstract ||
      !!pros_cons ||
      !!expected_effort_correctness ||
      !!realism ||
      !!required_resources_correctness ||
      !!other_characteristics) &&
    !author_id
  ) {
    errors.obligatory_project_review_attributes = {
      author_id: 'Author is required',
    };
  }

  return errors;
};

const ProjectListGrid = props => {
  let { data: projects } = useListContext();
  projects = projects || [];
  const projectIds = projects.map(p => p.id);

  let { data: projectOwnershipsRes } = useQuery(
    ['project_ownerships', 'getList'],
    () => dataProvider.getList('project_ownerships', { filter: { project_id: projectIds } }),
    { enabled: projects && projects.length > 0 }
  );
  let { data: projectOwnerships } = projectOwnershipsRes || {};
  projectOwnerships = projectOwnerships || [];

  const authorIds = projectOwnerships.map(po => po.author_id);
  let { data: authors, isLoading: authorsLoading } = useGetMany(
    'users',
    { ids: authorIds },
    { enabled: authorIds.length > 0 }
  );
  authors = authors || [];

  const authorsLoaded = !authorsLoading && authors.length > 0;

  return (
    <Datagrid {...props}>
      <TextField source="title" />
      <TextField source="short_abstract" className="projects__abstract" />
      <ReferenceField
        source="project_category_id"
        reference="project_categories"
        link={projectCategoryLink}
      >
        <TextField source="name" />
      </ReferenceField>
      <ProjectAuthors
        source="users"
        label="Authors"
        projectOwnerships={projectOwnerships}
        authors={authors}
        authorsLoaded={authorsLoaded}
        sortable={false}
      />
      <TextField source="status" />
      <EditButton />
    </Datagrid>
  );
};

const ProjectForm = props => {
  return (
    <TabbedForm {...props} validate={projectValidation}>
      <FormTab label="summary">
        <Grid container width={{ xs: '100%', sm: 600 }}>
          <TextInput fullWidth source="title" />
          <TextInput fullWidth source="short_abstract" multiline />
          <TextInput fullWidth source="abstract" multiline />
          <TextInput fullWidth source="link" type="url" validate={urlValidation} />
          <ProjectCategoryInput />
          <ProjectAuthorsArrayInputField />
          <EditorsPickInput />
          <SelectInput
            fullWidth
            source="status"
            choices={[
              { id: 'active', name: 'Active' },
              { id: 'inactive', name: 'Inactive' },
            ]}
          />
          <TextInput fullWidth source="url_slug" />
          <TextInput fullWidth source="url_slug_alternative" />
          <ImageInput fullWidth source="img_src" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>
        </Grid>
      </FormTab>
      <FormTab label="project map">
        <Grid container width={{ xs: '100%', xl: 600 }}>
          <TextInput
            fullWidth
            source="project_map.affected_topics"
            label="Затрагиваемые смежные сферы"
          />
          <TextInput
            fullWidth
            source="project_map.circumstances_needed"
            label="Необходимые предпосылки"
          />
          <TextInput fullWidth source="project_map.developer" label="Разработчик" />
          <TextInput
            fullWidth
            source="project_map.resources_needed"
            label="Необходимые ресурсы/механизм реализации"
          />
          <TextInput
            fullWidth
            source="project_map.summary"
            label="Резюме (тизер) ожидаемых результатов и эффекта"
          />
        </Grid>
      </FormTab>
      <FormTab label="obligatory review">
        <Grid container width={{ xs: '100%', xl: 600 }}>
          <FiveStarsRankingInput source="obligatory_project_review_attributes.rank" />
          <TextInput
            fullWidth
            source="obligatory_project_review_attributes.abstract"
            label="Опишите проект в 5-10 словах"
          />
          <TextInput
            fullWidth
            source="obligatory_project_review_attributes.overall_assessment"
            label="Общая оценка проекта"
            multiline
          />
          <TextInput
            fullWidth
            source="obligatory_project_review_attributes.pros"
            label="Сильные стороны"
            multiline
          />
          <TextInput
            fullWidth
            source="obligatory_project_review_attributes.cons"
            label="Слабые стороны"
            multiline
          />
          <TextInput
            fullWidth
            source="obligatory_project_review_attributes.other_comments"
            label="Прочие комментарии, замечания, предложения"
            multiline
          />
          <TextInput
            fullWidth
            source="obligatory_project_review_attributes.free_form"
            label="Свободная форма (если есть будет использована вместо предыдущих полей)"
            multiline
          />
          <ReferenceInput
            label="Автор"
            source="obligatory_project_review_attributes.author_id"
            reference="experts"
          >
            <SelectInput
              fullWidth
              optionText={r => `${r.first_name} ${r.last_name} - ${r.position} @ ${r.organization}`}
            />
          </ReferenceInput>
        </Grid>
      </FormTab>
      <FormTab label="description">
        <DescriptionInput htmlSource="description" />
      </FormTab>
      <FormTab label="description preview">
        <DescriptionPreview />
      </FormTab>
    </TabbedForm>
  );
};

export const ProjectList = props => {
  return (
    <List {...props}>
      <ProjectListGrid />
    </List>
  );
};

export const ProjectEdit = props => {
  const queryClient = useQueryClient();
  const notify = useNotify();
  const redirect = useRedirect();

  const invalidateCache = data => {
    queryClient.invalidateQueries(['project_ownerships', 'getList']);
    notify('ra.notification.updated', {
      messageArgs: { smart_count: 1 },
    });
    redirect('/projects');
  };

  return (
    <Edit {...props} transform={transformForm} mutationOptions={{ onSettled: invalidateCache }}>
      <ProjectForm />
    </Edit>
  );
};

export const ProjectCreate = props => (
  <Create {...props} transform={transformForm}>
    <ProjectForm />
  </Create>
);
