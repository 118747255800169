import React from 'react';
import { useUpdate } from 'react-admin';
import Button from '../button';
import {
  STATUS_CONFIRMED,
  STATUS_REJECTED,
  STATUS_PENDING,
} from '../../constants/expertStatusRequest';
import './styles.css';

const ConfirmButton = props => {
  const [mutate] = useUpdate(props.resource, {
    id: props.record.id,
    data: { status: STATUS_CONFIRMED },
  });

  return (
    <Button variant="outlined" color="primary" onClick={mutate}>
      Confirm
    </Button>
  );
};

const RejectButton = props => {
  const [mutate] = useUpdate(props.resource, {
    id: props.record.id,
    data: { status: STATUS_REJECTED },
  });

  return (
    <Button variant="outlined" color="primary" onClick={mutate}>
      Reject
    </Button>
  );
};

const ActionButtons = props => {
  let confirmVisible = false;
  let rejectVisible = false;

  switch (props.record.status) {
    case STATUS_PENDING:
      confirmVisible = true;
      rejectVisible = true;
      break;
    case STATUS_CONFIRMED:
      rejectVisible = true;
      break;
    case STATUS_REJECTED:
      confirmVisible = true;
      break;
    default:
      console.log(`unknow record status: ${props.record.status}`);
  }

  return (
    <div className="expert-status-requests__action-buttons">
      {confirmVisible && <ConfirmButton {...props} />}
      {rejectVisible && <RejectButton {...props} />}
    </div>
  );
};

export default ActionButtons;
