import React, { useState, useRef } from 'react';
import { useController } from 'react-hook-form';
import './styles.css';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import Button from '../button';

const UploadAvatar = ({ source, removeAvatarSource, ...other }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [previewSrc, setPreviewSrs] = useState();
  const cropperRef = useRef();

  const avatarInput = useController({ name: source });
  const removeAvatarInput = useController({ name: removeAvatarSource });

  const editAvatar = e => {
    if (e.target.files.length === 0) {
      return;
    }

    const reader = new FileReader();
    reader.onload = e => {
      if (!e.target.result) {
        return;
      }

      setIsEditing(true);
      setPreviewSrs(e.target.result);
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const deleteAvatar = e => {
    removeAvatarInput.field.onChange(true);
    avatarInput.field.onChange(null);
  };

  const onCropperInitialized = cropper => {
    cropperRef.current = cropper;
  };

  const onCrop = () => {
    const cropper = cropperRef.current;
    const dataUrl = cropper.getCroppedCanvas().toDataURL();

    avatarInput.field.onChange(dataUrl);
    removeAvatarInput.field.onChange(false);
    setIsEditing(false);
  };

  return (
    <div>
      {isEditing ? (
        <Preview src={previewSrc} onCropperInitialized={onCropperInitialized} onCrop={onCrop} />
      ) : (
        <CurrentAvatar
          value={avatarInput.field.value}
          onEdit={editAvatar}
          onDelete={deleteAvatar}
        />
      )}
    </div>
  );
};

const CurrentAvatar = ({ value, onEdit, onDelete }) => {
  return (
    <div className="upload-avatar__current">
      {!!value ? (
        <div>
          <img src={value} alt="avatar" />
          <i className="upload-avatar__remove-icon fas fa-times" onClick={onDelete} />
        </div>
      ) : (
        <Placeholder onEdit={onEdit} />
      )}
    </div>
  );
};

const Placeholder = ({ onEdit }) => {
  return (
    <div className="upload-avatar__placeholder">
      <div className="upload-avatar__placeholder__upload">
        <label>
          Upload
          <input type="file" accept="image/*" onChange={onEdit} />
        </label>
      </div>
    </div>
  );
};

const Preview = ({ src, onCropperInitialized, onCrop }) => {
  return (
    <div className="upload-avatar__preview">
      <Cropper src={src} aspectRatio={1} viewMode={2} onInitialized={onCropperInitialized} />
      <Button color="primary" onClick={onCrop}>
        Crop
      </Button>
    </div>
  );
};

export default UploadAvatar;
