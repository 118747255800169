import React from 'react';
import { AppBar as RaAppBar } from 'react-admin';
import { Box, Typography, IconButton, Tooltip } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { deploy } from './api/deploy';

const AppBar = props => {
  const onDeploy = async e => {
    e.preventDefault();
    const result = await deploy();
    const state = result?.json?.job?.state;

    console.log('Deployed: ', result?.json);
    setDeployStatus(state);
  };

  const [deployStatus, setDeployStatus] = React.useState(null);
  const deployDisabled = deployStatus === 'PENDING';

  return (
    <RaAppBar {...props}>
      <Box flex="1">
        <Typography variant="h6" color="inherit" id="react-admin-title" />
      </Box>
      <Tooltip title="Deploy">
        <IconButton
          aria-label="deploy"
          color="inherit"
          onClick={onDeploy}
          disabled={deployDisabled}
        >
          <CloudUploadIcon />
        </IconButton>
      </Tooltip>
    </RaAppBar>
  );
};

export default AppBar;
