import React, { useState, useEffect } from 'react';
import { Toolbar, DeleteButton, ToolbarClasses, SaveButton, useEditContext } from 'react-admin';
import { useFormState, useWatch } from 'react-hook-form';

import './style.css';

const EditFormAutoSave = ({ waitInterval = 3000 }) => {
  const { isDirty, isValidating, isSubmitting, isValid } = useFormState();
  const values = useWatch();
  const { save } = useEditContext();
  const [message, setMessage] = useState('');

  useEffect(() => {
    let timeoutId = undefined;
    if (isDirty && isValid && !isValidating && !isSubmitting) {
      const fn = () => {
        setMessage('Autosaving...');
        save({ ...values }).then(() => {
          setMessage(`Autosaved: ${new Date().toLocaleString()}`);
        });
      };

      timeoutId = setTimeout(fn, waitInterval);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isDirty, isSubmitting, isValid, isValidating, save, values, waitInterval]);

  return <span className="last-saved">{message}</span>;
};

export const AutosaveToolbar = () => (
  <Toolbar>
    <div className={ToolbarClasses.defaultToolbar}>
      <SaveButton />
      <EditFormAutoSave />
      <DeleteButton resource="articles_layout_blocks" redirect={false} />
    </div>
  </Toolbar>
);
