import { Button as MaterialButton } from '@mui/material';
import classnames from 'classnames';
import './styles.css';

const Button = props => (
  <MaterialButton {...props} className={classnames('button', props.className)}>
    {props.children}
  </MaterialButton>
);

export default Button;
