import React from 'react';
import ReactDOM from 'react-dom';
import { useController } from 'react-hook-form';
import { Button } from 'react-admin';
import _debounce from 'lodash/debounce';
import CodeFlask from 'codeflask';

import { EditorState, Plugin } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { Schema, DOMParser, DOMSerializer } from 'prosemirror-model';
import { schema } from 'prosemirror-schema-basic';
import { exampleSetup, buildMenuItems } from 'prosemirror-example-setup';
import { MenuItem } from 'prosemirror-menu';
import { toggleMark } from 'prosemirror-commands';
import { addListNodes, wrapInList } from 'prosemirror-schema-list';

import { CustomCodeNodeSpec, CustomCodeView } from './nodes/customCode';

import { httpClient, apiUrl } from '../../api/dataProvider/base';

import './styles.css';
import { TooltipMarkSpec } from './marks/tooltip';
import { HighlightMarkSpec } from './marks/highlight';
import { UnderlineMarkSpec } from './marks/underline';
import { ParagraphNodeSpec } from './nodes/paragraph';
import { RedBulletListNodeSpec } from './nodes/redBulletList';

import {
  iconLeft,
  iconCenter,
  iconRight,
  iconInfo,
  iconUnderline,
  TextAlign,
  RTESelectionChangeEventName,
} from './common';
import { HeadingNodeSpec } from './nodes/heading';
import { ColorMarkSpec } from './marks/color';

import ColorPicker from './ColorPicker';

function RTEInput({ htmlSource }) {
  const htmlInput = useController({ name: htmlSource });

  return (
    <RichTextEditor HTMLvalue={htmlInput.field.value} onHTMLChange={htmlInput.field.onChange} />
  );
}

export default RTEInput;

const CustomNodes = {
  custom_code: CustomCodeNodeSpec,
  paragraph: ParagraphNodeSpec,
  heading: HeadingNodeSpec,
  red_bullet_list: RedBulletListNodeSpec,
};

/**
 * Order **does** matter here: we want `color` mark to be on the top of the marks list,
 * so it will always be outermost mark wrapping content
 */
const CustomMarks = {
  tooltip: TooltipMarkSpec,
  highlight: HighlightMarkSpec,
  underline: UnderlineMarkSpec,
  color: ColorMarkSpec,
};

const nodes = Object.entries(CustomNodes).reduce((acc, [key, spec]) => {
  return acc.get(key) ? acc.update(key, spec) : acc.addToEnd(key, spec);
}, schema.spec.nodes);

const marks = Object.entries(CustomMarks).reduce((acc, [key, spec]) => {
  return acc.get(key) ? acc.update(key, spec) : acc.addToStart(key, spec);
}, schema.spec.marks);

const RTESchema = new Schema({
  nodes: addListNodes(nodes, 'paragraph block*', 'block'),
  marks,
});

let customCodeType = RTESchema.nodes.custom_code;

const menu = buildMenuItems(RTESchema);

menu.insertMenu.content.push(
  new MenuItem({
    title: 'Insert custom code',
    label: 'Custom code',
    enable() {
      return true;
    },
    run(state, dispatch, view) {
      view.hooks.requestCodeEditor();
    },
  })
);

menu.insertMenu.content.push(
  new MenuItem({
    title: 'Wrap in custom red bullet list',
    label: 'Red Bullet list',
    enable() {
      return true;
    },
    run(state, dispatch, view) {
      const cmd = wrapInList(RTESchema.nodes.red_bullet_list);
      return cmd(state, dispatch, view);
    },
  })
);

menu.insertMenu.content[0] = new MenuItem({
  title: 'Insert image from your device',
  label: 'Image',
  enable() {
    return true;
  },
  run(state, dispatch) {
    const event = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    document.getElementById('image-upload').dispatchEvent(event);
  },
});

const runAlign = (state, dispatch, align) => {
  const { from, to } = state.selection;
  const tr = state.tr;
  state.doc.nodesBetween(from, to, (node, pos) => {
    if (node.isBlock) {
      tr.setNodeMarkup(pos, undefined, {
        align,
      });
    }
  });
  dispatch(tr);
};

const alignMenu = [
  new MenuItem({
    title: 'Align Text Left',
    icon: {
      dom: iconLeft,
    },
    enable({ selection }) {
      return true;
    },
    run(state, dispatch) {
      runAlign(state, dispatch, TextAlign.Left);
    },
  }),
  new MenuItem({
    title: 'Align Text Center',
    icon: {
      dom: iconCenter,
    },
    enable({ selection }) {
      return true;
    },
    run(state, dispatch) {
      runAlign(state, dispatch, TextAlign.Center);
    },
  }),
  new MenuItem({
    title: 'Align Text Right',
    icon: {
      dom: iconRight,
    },
    enable({ selection }) {
      return true;
    },
    run(state, dispatch) {
      runAlign(state, dispatch, TextAlign.Right);
    },
  }),
];

menu.fullMenu.splice(1, 0, alignMenu);

menu.fullMenu[0].push(
  new MenuItem({
    title: 'Add tooltip',
    icon: {
      dom: iconInfo,
    },
    enable({ selection }) {
      return !selection.empty;
    },
    run(state, dispatch) {
      let { doc, selection } = state;
      if (selection.empty) return false;
      let attrs = null;
      if (!doc.rangeHasMark(selection.from, selection.to, RTESchema.marks.tooltip)) {
        attrs = { text: prompt('Enter tooltip text:', '') };
        if (!attrs.text) return false;
      }
      return toggleMark(RTESchema.marks.tooltip, attrs)(state, dispatch);
    },
  })
);

menu.fullMenu[0].push(
  new MenuItem({
    title: 'Add highlight',
    render(view) {
      const div = document.createElement('div');
      div.className = 'ProseMirror-icon';
      ReactDOM.render(
        <ColorPicker
          view={view}
          colorMarkType={RTESchema.marks.highlight}
          iconName="highlighter"
          onChange={highlight => {
            const { state, dispatch } = view;

            const { tr, selection } = state;
            if (selection.empty) {
              return;
            }

            const attrs = { highlight };
            const { from, to } = selection;
            tr.removeMark(from, to, RTESchema.marks.highlight);
            tr.addMark(from, to, RTESchema.marks.highlight.create(attrs));
            dispatch(tr);
          }}
        />,
        div
      );
      return div;
    },
    enable({ selection }) {
      return !selection.empty;
    },
    run(state, dispatch) {
      let { selection } = state;
      if (selection.empty) {
        return false;
      }
      return true;
    },
  })
);

menu.fullMenu[0].push(
  new MenuItem({
    title: 'Font Color',
    render(view) {
      const div = document.createElement('div');
      div.className = 'ProseMirror-icon';
      ReactDOM.render(
        <ColorPicker
          view={view}
          colorMarkType={RTESchema.marks.color}
          iconName="a"
          onChange={color => {
            const { state, dispatch } = view;

            const { tr, selection } = state;
            if (selection.empty) {
              return;
            }

            const attrs = { color };
            const { from, to } = selection;
            tr.removeMark(from, to, RTESchema.marks.color);
            tr.addMark(from, to, RTESchema.marks.color.create(attrs));
            dispatch(tr);
          }}
        />,
        div
      );
      return div;
    },
    run(state, dispatch) {
      return true;
    },
  })
);

menu.fullMenu[0].unshift(
  new MenuItem({
    title: 'Toggle underlined style',
    icon: {
      dom: iconUnderline,
    },
    enable({ selection }) {
      return true;
    },
    run(state, dispatch) {
      let { selection } = state;
      if (selection.empty) {
        return false;
      }
      return toggleMark(RTESchema.marks.underline)(state, dispatch);
    },
  })
);

class RichTextEditor extends React.Component {
  /**
   * @type React.RefObject<EditorView<typeof RTESchema>>
   */
  instance = React.createRef();
  /**
   * @type React.RefObject<CodeFlask>
   */
  flask = React.createRef();
  contentRef = React.createRef();

  state = {
    codeEditorOpen: false,
    codeEditorPromise: null,
  };

  getHTML = () => {
    const div = document.createElement('div');
    const fragment = DOMSerializer.fromSchema(RTESchema).serializeFragment(
      this.instance.current.state.doc.content
    );

    div.appendChild(fragment);

    return div.innerHTML;
  };

  handleContentChange = _debounce(
    () => {
      const html = this.getHTML();
      this.props.onHTMLChange?.(html);
    },
    1000,
    { trailing: true, leading: false }
  );

  initEditor = ref => {
    if (!this.instance.current) {
      const handleContentChange = this.handleContentChange;

      this.instance.current = new EditorView(ref, {
        nodeViews: {
          custom_code(node, view, getPos) {
            return new CustomCodeView(node, view, getPos);
          },
        },
        state: EditorState.create({
          doc: DOMParser.fromSchema(RTESchema).parse(this.contentRef.current),
          plugins: [
            ...exampleSetup({
              schema: RTESchema,
              menuContent: menu.fullMenu,
            }),
            new Plugin({
              view(view) {
                return {
                  update: function (view, prevState) {
                    const state = view.state;

                    if (prevState && !prevState.selection.eq(state.selection)) {
                      document.dispatchEvent(new CustomEvent(RTESelectionChangeEventName));
                    }
                  },
                };
              },
            }),
          ],
        }),
        dispatchTransaction(tr) {
          handleContentChange();
          this.updateState(this.state.apply(tr));
        },
      });

      this.instance.current.hooks = {
        requestCodeEditor: this.requestCodeEditor,
      };
    }
  };

  initCodeEditor = el => {
    if (el) {
      this.flask.current = new CodeFlask(el, {
        language: 'html',
        lineNumbers: true,
      });
    }
  };

  requestCodeEditor = () => {
    this.setState({
      codeEditorOpen: true,
    });
  };

  applyCode = () => {
    const code = this.flask.current.getCode();
    // const code = `<div class="flourish-embed flourish-chart" data-src="visualisation/8178618"><script src="https://public.flourish.studio/resources/embed.js"></script></div>`;
    const { state, dispatch } = this.instance.current;
    if (code) {
      dispatch(state.tr.replaceSelectionWith(customCodeType.create({ customCode: code })));
    }
    this.discardCodeEditor();
  };

  discardCodeEditor = () => {
    this.setState({
      codeEditorOpen: false,
    });
  };

  onImageUpload = e => {
    const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    if (files.length > 0) {
      this.imageUploadHandler(files);
    }
  };

  imageUploadHandler = async files => {
    const promises = [];
    const publicUrls = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      const getUploadLinkUrl = new URL(`${apiUrl}/admin/assets/upload_link`);
      getUploadLinkUrl.search = new URLSearchParams({
        filename: file.name,
      }).toString();

      const urlData = await httpClient(getUploadLinkUrl);
      const url = urlData.json.url;

      publicUrls[i] = urlData.json.public_url;
      promises[i] = fetch(url, { method: 'PUT', body: file });
    }

    await Promise.all(promises);
    this.insertImage(publicUrls[0]);
  };

  insertImage = src => {
    const { state, dispatch } = this.instance.current;
    dispatch(state.tr.replaceSelectionWith(RTESchema.nodes.image.create({ src })));
  };

  render() {
    const {
      HTMLvalue,
      // input,
      // onHTMLChange
    } = this.props;

    return (
      <>
        <div
          ref={this.contentRef}
          id="rte-content"
          style={{ display: 'none' }}
          dangerouslySetInnerHTML={{
            __html: HTMLvalue,
            // __html: Example.description,
          }}
        ></div>
        <div ref={this.initEditor} id="rte">
          {this.state.codeEditorOpen && (
            <div className="code-editor-wrapper">
              <div ref={this.initCodeEditor} className="code-editor"></div>
              <div className="code-editor-controls">
                <Button label="Cancel" color="secondary" onClick={this.discardCodeEditor}></Button>
                <Button
                  label="Apply"
                  color="secondary"
                  variant="contained"
                  onClick={this.applyCode}
                ></Button>
              </div>
            </div>
          )}
        </div>

        <input
          onChange={this.onImageUpload}
          style={{ display: 'none' }}
          type="file"
          id="image-upload"
          accept="image/png, image/jpeg"
        />
      </>
    );
  }
}
