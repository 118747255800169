import React, { useEffect, useState } from 'react';
import { ListContextProvider, useGetList, useList, useListContext } from 'react-admin';
import { useLocation } from 'react-router-dom';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { cloneDeep } from 'lodash';

const convertDataHashToTree = data => {
  const tree = cloneDeep(data);

  let roots = [];
  Object.values(tree).forEach(el => {
    if (el.parent_category_id === null) {
      roots = [...roots, el];
      return;
    }

    const parentEl = tree[el.parent_category_id];
    parentEl.children = [...(parentEl.children || []), el];
  });

  return roots;
};

const buildTreeItem = item => {
  if (!item.children) {
    return <TreeItem nodeId={Number(item.id).toString()} key={item.id} label={item.name} />;
  } else {
    return (
      <TreeItem nodeId={Number(item.id).toString()} key={item.id} label={item.name}>
        {item.children.map(buildTreeItem)}
      </TreeItem>
    );
  }
};

const ProjectCategoryTree = () => {
  let { data, isLoading } = useListContext();
  const [dataHash, setDataHash] = useState(null);
  useEffect(() => {
    if (isLoading) {
      return;
    }

    let hash = data.reduce((hash, el) => {
      hash[el.id] = el;
      return hash;
    }, {});
    setDataHash(hash);
  }, [data, isLoading]);

  const roots = convertDataHashToTree(dataHash || {});

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const selectedNodeId = params.get('selectedNodeId');

  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  useEffect(() => {
    if (!!dataHash && selectedNodeId) {
      let node = dataHash[selectedNodeId];
      let expendedNodes = [];
      while (node.parent_category_id) {
        expendedNodes = [...expendedNodes, Number(node.parent_category_id).toString()];
        node = dataHash[node.parent_category_id];
      }
      setExpanded(expendedNodes);
      setSelected([selectedNodeId]);
    }
  }, [selectedNodeId, dataHash]);

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      expanded={expanded}
      onNodeToggle={handleToggle}
      selected={selected}
      onNodeSelect={handleSelect}
      multiSelect
    >
      {roots.map(buildTreeItem)}
    </TreeView>
  );
};

export const ProjectCategoryList = props => {
  const { data, isLoading } = useGetList('project_categories', { pagination: null });

  const listContext = {
    isLoading: isLoading,
    data,
    basePath: 'project_categories',
  };

  console.log(listContext);

  return (
    <ListContextProvider value={listContext}>
      <ProjectCategoryTree />
    </ListContextProvider>
  );
};
