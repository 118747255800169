import React, { useRef } from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  TextInput,
  RadioButtonGroupInput,
  Create,
  SimpleForm,
  ArrayInput,
  SimpleFormIterator,
  useEditController,
  FormDataConsumer,
  BooleanInput,
} from 'react-admin';
import { serialize } from 'object-to-formdata';
import { urlValidator } from './utils/validators';
import { buildNestedAttributesArray } from './utils/railsHelper';
import ImageUploaderInput from './components/imageUploaderInput';

const transformForm = async (record, data) => {
  const existingResults = (record || {}).survey_results || [];
  data['survey_results_attributes'] = buildNestedAttributesArray(
    existingResults,
    data['survey_results']
  );
  delete data['survey_results'];

  const formData = serialize(data, null, null, 'survey');
  return formData;
};

export const SurveyList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="title" />
      <TextField source="status" />
      <EditButton />
    </Datagrid>
  </List>
);

const SurveyForm = props => {
  return (
    <SimpleForm {...props}>
      <TextInput source="title" />
      <TextInput source="description" />
      <TextInput source="url" type="url" validate={urlValidator} />
      <RadioButtonGroupInput
        source="status"
        choices={[
          { id: 'submitted', name: 'Submitted' },
          { id: 'in_progress', name: 'In progress' },
          { id: 'suspended', name: 'Suspended' },
          { id: 'completed', name: 'Completed' },
          { id: 'deleted', name: 'Deleted' },
        ]}
      />
      <BooleanInput source="on_main_page" />
      <ArrayInput source="survey_results">
        <SimpleFormIterator>
          <TextInput source="description" label="Description" />
          <TextInput multiline source="raw_html" label="HTML" />
          <FormDataConsumer>
            {({ scopedFormData }) =>
              scopedFormData && (
                <details>
                  <summary>HTML Preview</summary>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: scopedFormData.raw_html,
                    }}
                  />
                </details>
              )
            }
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
      <ImageUploaderInput source="image_url" />
    </SimpleForm>
  );
};

export const SurveyEdit = props => {
  const { record } = useEditController(props);
  const recordRef = useRef();
  recordRef.current = record;

  return (
    <Edit {...props} transform={data => transformForm(recordRef.current, data)}>
      <SurveyForm />
    </Edit>
  );
};

export const SurveyCreate = props => {
  const record = null;
  return (
    <Create {...props} transform={transformForm.bind(this, record)}>
      <SurveyForm />
    </Create>
  );
};
