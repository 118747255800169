import { httpClient, apiUrl } from '../api/dataProvider/base';

const dataURLToBlob = dataURL => {
  const byteString = atob(dataURL.split(',')[1]);
  const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];

  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
};

const uploadAsset = async rawFile => {
  const getUploadLinkUrl = new URL(`${apiUrl}/admin/assets/upload_link`);
  getUploadLinkUrl.search = new URLSearchParams({
    fileName: rawFile.name,
  }).toString();

  const urlData = await httpClient(getUploadLinkUrl);
  const url = urlData.json.url;
  const publicUrl = urlData.json.public_url;

  await fetch(url, { method: 'PUT', body: rawFile });
  return publicUrl;
};

export { dataURLToBlob, uploadAsset };
