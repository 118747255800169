import React from 'react';
import Rating from '@mui/material/Rating';
import Grid from '@mui/material/Grid';
import { useController } from 'react-hook-form';

const FiveStarsRankgingInput = ({ source }) => {
  const rankingInput = useController({ name: source });

  return (
    <Grid item xs={12}>
      <Rating {...rankingInput.field} precision={1} />{' '}
    </Grid>
  );
};

export default FiveStarsRankgingInput;
