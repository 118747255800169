import React, { useRef } from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  RadioButtonGroupInput,
  useEditController,
  BooleanInput,
  NumberInput,
} from 'react-admin';
import { urlValidator } from './utils/validators';
import { serialize } from 'object-to-formdata';
import ImageUploaderInput from './components/imageUploaderInput';

const transformForm = async (record, data) => {
  const formData = serialize(data, null, null, 'community');

  return formData;
};

export const SocietyList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="description" />
      <TextField source="platform" />
      <TextField source="url" type="url" validate={urlValidator} />
      <TextField source="size" />
      <EditButton />
    </Datagrid>
  </List>
);

const SocietyForm = props => {
  return (
    <SimpleForm {...props}>
      <TextInput source="name" />
      <TextInput source="description" />
      <RadioButtonGroupInput
        source="platform"
        choices={[
          { id: 'telegram', name: 'Telegram' },
          { id: 'facebook', name: 'Facebook' },
          { id: 'other', name: 'Other' },
        ]}
      />
      <TextInput source="url" type="url" validate={urlValidator} />
      <NumberInput source="size" min={0} />
      <BooleanInput source="is_public" initialValue={true} />
      <ImageUploaderInput source="image_url" />
    </SimpleForm>
  );
};

export const SocietyEdit = props => {
  const { record } = useEditController(props);
  const recordRef = useRef();
  recordRef.current = record;

  return (
    <Edit {...props} transform={data => transformForm(recordRef.current, data)}>
      <SocietyForm />
    </Edit>
  );
};

export const SocietyCreate = props => {
  const record = null;
  return (
    <Create {...props} transform={transformForm.bind(this, record)}>
      <SocietyForm />
    </Create>
  );
};
