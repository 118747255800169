import decode from 'jwt-decode';

const authProvider = {
  login: ({ username, password }) => {
    const stagingToken = localStorage.getItem('stagingToken');
    const request = new Request(process.env.REACT_APP_API_URL + '/users/sign_in', {
      method: 'POST',
      body: JSON.stringify({ user: { email: username, password } }),
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-Staging-Token': stagingToken,
      }),
    });

    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }

        const bearer = response.headers.get('Authorization');
        const jwt = bearer.split(' ')[1];
        localStorage.setItem('jwt', jwt);

        return response.json();
      })
      .then(user => {
        localStorage.setItem('user', JSON.stringify(user));
      })
      .catch(() => {
        throw new Error('Network error');
      });
  },
  logout: () => {
    const stagingToken = localStorage.getItem('stagingToken');
    const request = new Request(process.env.REACT_APP_API_URL + '/users/sign_out', {
      method: 'POST',
      headers: new Headers({ 'X-Staging-Token': stagingToken }),
    });

    return fetch(request).then(_ => {
      if (!!localStorage.getItem('user')) {
        // if local storage contains user it means we were actually logged in.
        // react-admin also calls logout before first sign in, we don't need to clear staging token in this case
        localStorage.removeItem('stagingToken');
      }
      localStorage.removeItem('user');
      localStorage.removeItem('jwt');
    });
  },
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('user');
      localStorage.removeItem('jwt');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    const jwt = localStorage.getItem('jwt');
    if (!jwt) {
      return Promise.reject();
    }

    const { exp } = decode(jwt);
    return Date.now() >= exp * 1000 ? Promise.reject() : Promise.resolve();
  },
  getPermissions: () => Promise.resolve(),
};

export default authProvider;
