import React, { useState, useEffect } from 'react';
import {
  required,
  SimpleForm,
  ReferenceInput,
  BooleanInput,
  AutocompleteInput,
  NumberInput,
  Toolbar,
  DeleteButton,
  ToolbarClasses,
  useUpdate,
  useRecordContext,
  useCreate,
  useGetList,
  RecordContextProvider,
  SaveButton,
} from 'react-admin';
import { Stack, CardContent, Button } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import ImageUploaderInput from './components/imageUploaderInput';
import { serialize } from 'object-to-formdata';

const FormToolbar = (
  <Toolbar>
    <div className={ToolbarClasses.defaultToolbar}>
      <SaveButton />
      <DeleteButton resource="articles_layout_blocks" redirect={false} />
    </div>
  </Toolbar>
);

const BlockForm = ({ record, onSubmit, label }) => (
  <SimpleForm
    record={record}
    resource="articles_layout_block"
    onSubmit={onSubmit}
    toolbar={FormToolbar}
  >
    <ReferenceInput source="article_id" reference="articles" validate={required()}>
      <AutocompleteInput label={label} optionText="title" sx={{ width: '600px' }} />
    </ReferenceInput>
    <ImageUploaderInput source="preview_img_url" withCropper />
    <BooleanInput label="Статья месяца" source="additional.article_of_the_month" />
    {record.block_type !== 'main' && <NumberInput label="Позиция" source="additional.position" />}
  </SimpleForm>
);

const CreateBlockForm = ({ label }) => {
  const record = useRecordContext();
  const [mutate, { isLoading }] = useCreate();

  const onSubmit = data => {
    const formData = serialize(data, { booleansAsIntegers: true }, null, 'articles_layout_block');

    mutate('articles_layout_blocks', { data: formData });
  };

  return <BlockForm record={record} onSubmit={onSubmit} label={label} />;
};

const UpdateBlockForm = ({ label }) => {
  const record = useRecordContext();
  const [mutate, { isLoading }] = useUpdate();

  const onSubmit = data => {
    const formData = serialize(data, { booleansAsIntegers: true }, null, 'articles_layout_block');

    mutate('articles_layout_blocks', { id: record.id, data: formData, previousData: record });
  };

  return <BlockForm record={record} onSubmit={onSubmit} label={label} />;
};

const PageLayoutBlockForm = ({ record }) => {
  const label = record.block_type === 'main' ? 'Главная статья' : 'Рекомендуемая статья';

  return (
    <RecordContextProvider value={record}>
      {record.temporaryId ? <CreateBlockForm label={label} /> : <UpdateBlockForm label={label} />}
    </RecordContextProvider>
  );
};

export const ArticlesPageLayoutEdit = ({ staticContext, ...props }) => {
  const [blocks, setBlocks] = useState([]);

  const { data: apiData } = useGetList('articles_layout_blocks');

  useEffect(() => {
    if (!apiData) return;
    const blocks = [...apiData];

    const mainBlockExists = blocks.some(block => block.block_type === 'main');
    if (!mainBlockExists) {
      blocks.push({
        id: uuidv4(),
        block_type: 'main',
        temporaryId: true,
      });
    }

    setBlocks(blocks.sort((a, b) => (a.block_type === 'main' ? -1 : 1)));
  }, [apiData]);

  const addSuggestedArticle = () => {
    setBlocks([
      ...blocks,
      {
        id: uuidv4(),
        block_type: 'suggested',
        temporaryId: true,
      },
    ]);
  };

  return (
    <CardContent>
      <Stack alignItems="flex-start">
        {/* first article is always main article */}
        {blocks[0] && <PageLayoutBlockForm record={blocks[0]} />}

        {/* recommended articles */}
        {blocks.slice(1).map(block => (
          <PageLayoutBlockForm record={block} />
        ))}

        <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={addSuggestedArticle}>
          Add recommended article
        </Button>
      </Stack>
    </CardContent>
  );
};
