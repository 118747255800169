const defaultColor = '#0000001a';

export const HighlightMarkSpec = {
  group: 'inline',
  inclusive: false,
  attrs: {
    highlight: { default: defaultColor },
  },
  toDOM(node) {
    return [
      'span',
      {
        'data-highlight': node.attrs.highlight,
        style: `background-color: ${node.attrs.highlight}`,
      },
      0,
    ];
  },
  parseDOM: [
    {
      tag: 'span.highlight, span[data-highlight]',
      getAttrs: dom => {
        return {
          highlight: dom.dataset.highlight || defaultColor,
        };
      },
    },
  ],
};
