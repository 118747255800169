import React from 'react';
import {
  Datagrid,
  List,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  ReferenceField,
  FunctionField,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput,
} from 'react-admin';

export const ExpertList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" label="User ID" />
      <TextField source="first_name" />
      <TextField source="last_name" />
      <TextField source="organization" label="Organization" />
      <TextField source="position" label="Position" />
      <EditButton />
    </Datagrid>
  </List>
);

export const ExpertEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <ReferenceField source="id" reference="users" label="Пользователь">
        <FunctionField render={record => `${record.first_name} ${record.last_name}`} />
      </ReferenceField>
      <TextInput source="organization" />
      <TextInput source="position" />
      <BooleanInput source="in_house" />
      <TextInput source="summary" multiline />
    </SimpleForm>
  </Edit>
);

export const ExpertCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput label="Пользователь" source="user_id" reference="users">
        <AutocompleteInput
          optionText={r => `${r.email}; ${r.first_name} ${r.last_name}`}
          sx={{ width: '191px' }}
        />
      </ReferenceInput>
      <TextInput source="organization" />
      <TextInput source="position" />
      <BooleanInput source="in_house" />
      <TextInput source="summary" multiline />
    </SimpleForm>
  </Create>
);
